.nav-bar {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: rgb(50, 125, 200);
  color: rgb(227, 227, 227);
  z-index: 2;

  .logo {
    width: 60px;
    height: auto;
    text-decoration: none;
    outline: none;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 90px 60px;
  }

  .navigation {
    margin: 0 15px;
    padding: 5px 5px;
  }
}



