.section-title {
  margin-bottom: 10px;
}

.section-title::before {
  font-family: "Fira Code", monospace;
  color: var(--light-green);
  font-size: 0.8em;
  font-weight: 200;
  counter-increment: title-counter;
  content: "0" counter(title-counter) ". ";
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  h1 {
    flex: 0 1 auto;
  }
  hr {
    border-color: var(--light-green);
    flex: 3 1 auto;
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .title-wrapper {
    align-self: flex-start;
  }

  .section-title {
    font-size: 1.5em;
  }

  .section-title::before {
    padding: 0 0 0 20px;
    content: "0" counter(title-counter) ".";
  }

  .title-wrapper hr {
    display: none;
  }
}
