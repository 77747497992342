//This font is very similar to VS code's font I use, so I like it :)
//used in the titles numerators
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300&family=League+Script&display=swap");

//To be replaced, the font for my name
@font-face {
  font-family: "Madelican";
  src: local("Madelican"), url(./Fonts/Madelican.otf) format("truetype");
  font-weight: normal;
}

//Global variables: (can also be defined using :root{})
* {
  --light-green: rgb(100, 255, 218); //#64ffda
  --background-color: rgb(10, 25, 47); //#0a192f
  --font-color: rgb(204, 214, 246); //#0a192f
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  .main-container {
    margin: 0 auto;
    font-family: "Signika Negative", sans-serif;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.04em;
    color: var(--font-color);
  }
  .nav-bar,
  .main-container,
  .footer {
    background-color: var(--background-color);
  }
  //Initializing counters must be in one line, otherwise it breaks.
  //See https://stackoverflow.com/questions/23980085/multiple-css-counters-not-working-as-expected
  counter-reset: title-counter nav-counter;
  //counter-reset: nav-counter; <=== this breaks the count for that variable

  @media (max-width: 768px) {
    font-size: 1.35em;
    //position: absolute;// this solves an annoying bug on chrome mobile view..
  }
}

a.link {
  position: relative;
  text-decoration: none;
  color: var(--light-green);
  display: inline-block; // <= this is because the underscore animation breaks if the tag breaks line
  // @media (max-width: 768px) {
  //   display:none;
  // }
}

a.link-mobile {
  position: relative;
  text-decoration: none;
  color: var(--light-green);
  display: inline-block; // <= this is because the underscore animation breaks if the tag breaks line
  @media (min-width: 768px) {
    display: none;
  }
}


button {
  cursor: pointer;
  border: 0px;
  border-radius: 0px;
}

//general responsive part

@media (max-width: 768px) {
  //override styled components?
}
