.title-wrapper{
    display: flex;
}

.position{
    font-size: 1.5em;
    text-decoration: underline;
    text-decoration-color: var(--light-green);
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
}

.company-link{
    font-size:1.5em;
    line-height: 1.2;
}

.company-link::before{
    bottom:8px !important;//todo: fix that, load order is incorrect
}
