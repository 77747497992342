.links-container {
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

a.contact-url {
  width: 50px;
  height: 50px;
  flex: 1;
  text-decoration: none;
  outline: none;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
  background-position: center;
  color: var(--font-color) !important;//overrides bootstrap

  //hides the anchor text, in favor of accesability 
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;

  &:hover{
    filter: drop-shadow(0 0 1mm rgb(220, 210, 220));
    color: var(--font-color) !important;//overrides bootstrap
  }

  &:active {
    opacity: 0.85;
    filter: drop-shadow(0 0 2mm var(--font-color));//this adds "box-shadow" to the bg image
  }
}

@media (max-width: 768px) {
  .links-container{
    width:auto;
    gap:45px;
  }

}
