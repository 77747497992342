
.tabs-container{
  flex: 0 0 150px;
}

button.tab {
  padding: 15px 10px;
  background-color: inherit;
  color: var(--font-color);
  opacity: 0.5;
  font-size: 1em;
  font-weight: 200;
  border-left: 2px solid var(--font-color);
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  

  &:hover{
    background-color: #233554;
    color: var(--light-green);
    border-color: var(--font-color);
    opacity: 0.8;
  }

  &.active{
    background-color: #233554;
    color: var(--light-green);
    opacity: 0.9;
    position: relative;
  }

  &.active::before{
      content: '';
      position: absolute;
      top: 0;
      left: -2px;
      height: 0;
      width: 0;
      z-index: 1;
      border-left: 2px solid;
      border-color: var(--light-green);
      animation: grow 1s linear;
      animation-fill-mode: forwards;
      opacity: 1;
  }
}

@keyframes grow {
  100% {
    height:100%
  }
}